<template>
  <base-section id="Professional Details" color="white">
    <v-container class="text-center">
      <v-row>
        <v-col cols="6" sm="6">
          <v-sparkline
            :fill="fill"
            :gradient="selectedGradient"
            :line-width="width"
            :padding="padding"
            :smooth="radius || false"
            :value="value"
            auto-draw
          />
        </v-col>
        <v-col cols="6" sm="6">
          <kdw-btn id="contact" color="rgba(73, 2, 124,1)" dark x-large>
            <div class="text-h4">Contactez-moi</div>
          </kdw-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="black--text font-weight-bold">
          @2022 Kidiwi Services
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionWaveProfessionalDetails",

  data: () => ({
    selectedGradient: ["rgba(157, 0, 255, 1)", "rgba(73, 2, 124,1)"],
    fill: true,
    padding: 0,
    radius: 24,
    value: [0, 9, 8, 7.2, 7, 10, 9, 8, 5, 10, 0],
    width: 2,
  }),
  computed: {},
  methods: {},
};
</script>
